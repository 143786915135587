import ResponsiveImage from '@/components/ResponsiveImage'
import Link from 'next/link'
import { memo } from 'react'

const lineItemImgLoader = ({ src }) => {
  return `${src}?w=400`
}

const LineItemProductImage = ({
  imageUrl,
  title,
  productUrl,
  isGiftOrder,
  isSubscriptionBox,
}) => {
  return (
    <Link
      className={`order-item__image ${isSubscriptionBox && !isGiftOrder ? 'image--subscription-box' : ''}`}
      href={productUrl}
      onClick={(e) => {
        if (isSubscriptionBox && !isGiftOrder) {
          e.preventDefault()
        }
      }}
    >
      <ResponsiveImage
        loader={lineItemImgLoader}
        src={imageUrl}
        alt={title}
        isGiftOrder={isGiftOrder}
      />
    </Link>
  )
}

export default memo(LineItemProductImage)
