import Link from 'next/link'
import { memo } from 'react'

const LineItemProductHeader = ({
  item,
  productUrl,
  cartContainsSwapProduct,
  isSubscription,
  isSubscriptionBox,
  isGiftOrder,
}) => {
  return (
    <div className="order-item__item-header">
      <Link
        className="order-item__title-link"
        href={productUrl}
        onClick={(e) => {
          if (isSubscriptionBox && !isGiftOrder) {
            e.preventDefault();
          }
        }}
      >
        <h3 className={`order-item__title ${isSubscriptionBox && !isGiftOrder? 'title-subscription-box' : ''}`}>
          {item.title}
        </h3>
      </Link>
      {item.properties.product_weight &&
        item.properties.product_weight != '' &&
        !(isSubscription && cartContainsSwapProduct) && (
          <div className="order-item__weight">
            <span className="weight-per-unit">
              {item.properties.product_weight}lbs{' '}
              {isSubscription && <>/ box </>}
            </span>
          </div>
        )}
    </div>
  )
}

export default memo(LineItemProductHeader)
