import PropTypes from 'prop-types'
import { LineItemProduct } from '../LineItemProduct'
import { memo } from 'react'

const LineItems = ({
  readOnly,
  lineItems: unfilteredLineItems,
  userLoggedIn,
  isGiftOrder,
}) => {
  function shouldDisplay(item) {
    // Put conditions for items that we want hidden here
    return item.title !== 'WelcomeCard'
  }

  const lineItems = unfilteredLineItems
    .filter((item) => shouldDisplay(item))
    .sort((a, b) => {
      function isPrepaidShipments(item) {
        return item.variant.sku?.includes('PREPAID-')
      }
      function isSubscription(item) {
        return item.product.tags?.includes('Subscription Box')
      }
      function isSwapProduct(item) {
        return item.variant.sku?.includes('SWP-')
      }

      if (isSubscription(a)) return -1
      else if (isSubscription(b)) return 1
      else if (isPrepaidShipments(a)) return -1
      else if (isPrepaidShipments(b)) return 1
      else if (isSwapProduct(a)) return -1
      else if (isSwapProduct(b)) return 1
      else return 0
    })
  const cartContainsSwapProduct = lineItems.some((li) =>
    li.variant.sku?.includes('SWP-'),
  )
  const cartContainsPrepaid = lineItems.some((li) =>
    li.variant.sku?.includes('PREPAID-'),
  )

  return (
    <div className="order-item-list">
      {lineItems
        .filter((item) => shouldDisplay(item))
        .map((item, i) => {
          {
            /*
          const isGift = item.properties.is_gift_order === 'true'
          const isSubscription = !!item.sellingPlanAllocation?.sellingPlan
          const showSubscriptionDisclaimer =
            isSubscription && !isGift && !readOnly
          const showGiftDisclaimer = item.properties.is_gift_order === 'true'
          */
          }

          return (
            <LineItemProduct
              userLoggedIn={userLoggedIn}
              readOnly={readOnly}
              item={item}
              key={`${item.title}-${i}`}
              cartContainsSwapProduct={cartContainsSwapProduct}
              cartContainsPrepaid={cartContainsPrepaid}
              isGiftOrder={isGiftOrder}
            >
              {item.quantity < 1 && (
                <div className="order-item__error">
                  <p>{`${item.merchandise.product.title} is sold out. Please remove it from your cart before continuing.`}</p>
                </div>
              )}
              {/* {(showSubscriptionDisclaimer || showGiftDisclaimer) && (
                <div className="order-item__disclaimer">
                  {showSubscriptionDisclaimer && (
                    <p>
                      I acknowledge that my subscription is continuous until
                      canceled. My credit card will be charged at the payment
                      intervals I have chosen above. I can manage my
                      subscription preferences or cancel the subscription
                      anytime in my user portal.
                    </p>
                  )}
                  {showGiftDisclaimer && (
                    <p>
                      This item is a Gift. As the purchaser, you will receive
                      the order confirmation and be able to manage the order.
                      The Giftee will receive separate notifications related to
                      delivery, but will not be able to manage the order or
                      create new orders on your account.
                    </p>
                  )}
                </div>
              )} */}
            </LineItemProduct>
          )
        })}
    </div>
  )
}

LineItems.propTypes = {
  lineItems: PropTypes.array,
}

export default memo(LineItems)
