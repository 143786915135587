import { memo } from 'react'
import LineItemProductImage from './LineItemProductImage'
import LineItemProductHeader from './LineItemProductHeader'
import QuantitySelector from './QuantitySelector'
import LineItemPrice from './LineItemPrice'

const LineItemProduct = ({
  item,
  children,
  readOnly,
  cartContainsSwapProduct,
  cartContainsPrepaid,
  isGiftOrder,
  // userLoggedIn,
}) => {
  const discounts =
    (item.discounts || item.discountAllocations)?.filter(
      (d) =>
        !!d.discountedAmount ||
        (d.discountApplication.targetSelection !== 'ALL' &&
          d.discountApplication.targetType === 'LINE_ITEM'),
    ) || []

  const productHandle = item.product?.handle || item.product?.content?.handle
  const isPrepaidShipments = item.variant.sku?.includes('PREPAID-')
  const isSubscription = !!item.sellingPlanAllocation?.sellingPlan
  // const isGiftCard = productHandle === 'digital-gift-card'
  const isPrepaidEarlyBird = productHandle === 'early-bird-prepaid'
  // const isSwapProduct = item.variant.sku?.includes('SWP-')
  // const hasDiscount = discounts && discounts.length > 0
  const isSubscriptionBox = item.product.tags?.includes('Subscription Box')

  let url = `/products/${productHandle}`;

  if (productHandle === 'salmon-subscription-box') {
    url = '/products/salmon-gift-subscription-box';
  } else if (productHandle === 'premium-seafood-subscription-box') {
    url = '/products/premium-seafood-gift-subscription-box';
  }

  if (isPrepaidEarlyBird) readOnly = true

  const imageUrl = item.product.content
    ? item.product.content.featuredMedia.thumbnailSrc
    : item.image.url

  // TODO: add this back for gift subs (note: the if statement is not currently correct at all for gift subs)
  // if (item.properties.membership_type && isSubscription) {
  //   // I am not confident this is accurate
  //   url = `/products/gift-subscription-box`
  // }

  const orderOptions = (
    <>
      {!isPrepaidShipments &&
        !!item.variant &&
        item.variant.title?.toLowerCase() !== 'default title' && (
          <div className="order-item__option-detail">
            <div className="order-item__option-title">Option</div>
            <div className="order-item__option">{item.variant.title}</div>
          </div>
        )}
      {isSubscription && (
        <div className="order-item__option-detail">
          <div className="order-item__option-title">Delivery Schedule</div>
          <div className="order-item__option delivery-option">
            {item.sellingPlanAllocation.sellingPlan.name}
          </div>
        </div>
      )}
    </>
  )

  // All these different if statements are a hot mess - we should redo the logic here so it is legible
  return (
    <div className="order-item checkout__row">
      <div className="order-item__main">
        <LineItemProductImage
          imageUrl={imageUrl}
          title={item.title}
          productUrl={url}
          isGiftOrder={isGiftOrder}
          isSubscriptionBox={isSubscriptionBox}
        />
        <div className="order-item__details">
          <div className="order-item__main-details">
            <div>
              <LineItemProductHeader
                productUrl={url}
                item={item}
                cartContainsSwapProduct={cartContainsSwapProduct}
                isSubscription={isSubscription}
                isSubscriptionBox={isSubscriptionBox}
                isGiftOrder={isGiftOrder}
              />
              <QuantitySelector
                item={item}
                allowQuantityAdjustment={!readOnly}
                allowDeletion={true}
                isPrepaidEarlyBird={isPrepaidEarlyBird}
                isPrepaidShipments={isPrepaidShipments}
              />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <LineItemPrice
                discounts={discounts}
                style={{ marginLeft: 'auto' }}
                item={item}
                cartContainsPrepaid={cartContainsPrepaid}
                isSubscription={isSubscription}
                isPrepaidShipments={isPrepaidShipments}
                // displayLoginPrompt={displayLoginPrompt}
              />
            </div>
          </div>
          <div className="order-item__options">{orderOptions}</div>
        </div>
      </div>
      <div className="order-item__options--mobile">{orderOptions}</div>
      {children}
    </div>
  )
}

export default memo(LineItemProduct)
